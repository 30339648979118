<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column width="400" prop="province_codes" label="可配送区域">
          <template #default="s">
            <div class="flex-def" style="width: 100%">
              <el-popconfirm @confirm="del(s.row.id)" title="确定删除吗？">
                <el-button slot="reference" style="width: 100%;color: red;padding-right: .5rem" type="text">删除</el-button>
              </el-popconfirm>
              <y_choose_area style="width: 100%" mode="show" v-model="s.row.province_codes"></y_choose_area>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="by_num_first_num" label="首件个数">
          <template #default="s">
            <el-input-number @change="edit(s.row)" v-model="s.row.by_num_first_num" :min="0"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="by_num_first_price" label="运费">
          <template #default="s">
            <el-input-number @change="edit(s.row)" v-model="s.row.by_num_first_price" :min="0"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="by_num_renew_num" label="续件个数">
          <template #default="s">
            <el-input-number @change="edit(s.row)" v-model="s.row.by_num_renew_num" :min="0"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="by_num_renew_price" label="续费">
          <template #default="s">
            <el-input-number @change="edit(s.row)" v-model="s.row.by_num_renew_price" :min="0"></el-input-number>
          </template>
        </el-table-column>
        <template #append>
          <div style="padding: .5rem">
            <y_choose_area @change="addArea">
              <el-button style="width: 100%">添加可配送区域</el-button>
            </y_choose_area>
          </div>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import y_choose_area from "@/components/shop/y_choose_area";
export default {
  name: "edit",
  components: {
    y_choose_area,
  },
  data() {
    return {
      shipping_id: false,
      list: [],
    }
  },
  mounted() {
    this.shipping_id = parseInt(this.$route.query.id);
    this.load();
  },
  methods: {
    del(id){
      this.$u.api.shop.goods.shippingItemDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(item){
      this.$u.api.shop.goods.shippingItemEdit(item).then(()=>{
        this.load();
      })
    },
    addArea(e){
      if(!e)return;
      this.$u.api.shop.goods.shippingItemEdit({
        shipping_id:this.shipping_id,
        province_codes:e
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.shop.goods.shippingItemAll({id: this.shipping_id}).then(res => {
        this.list = res;
      })
    }
  }
}
</script>

<style scoped>

</style>